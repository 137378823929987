import React from "react";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";
import Slide from '@mui/material/Slide';
import { CHAR_TOOLTIP_TITLE } from "../../../constants/Constants";

const Stats = ({
  status,
  wpm,
  countDown,
  countDownConstant,
  statsCharCount,
  rawKeyStrokes,
}) => {
  return (
    <>
      <h3>{countDown}s </h3>
      {status !== "finished" && (
        <Box display="flex" flexDirection="row">
          <h3>WPM: {isFinite(Math.round(wpm)) ? Math.round(wpm) : 0}</h3>
        </Box>
      )}
      <Slide direction="down" in={status === "finished"} mountonEnter unmountOnExit>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row">
          {status === "finished" && (<h3>WPM: {isFinite(Math.round(wpm)) ? Math.round(wpm) : 0}</h3>)}
          {status === "finished" && (
            <h3>Accuracy: {Math.round(statsCharCount[0])} %</h3>
          )}
          {status === "finished" && (
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  {CHAR_TOOLTIP_TITLE}
                </span>
              }
            >
              <h3>
                Char:{" "}
                <span className="correct-char-stats">{statsCharCount[1]}</span>/
                <span className="incorrect-char-stats">{statsCharCount[2]}</span>/
                <span className="missing-char-stats">{statsCharCount[3]}</span>/
                <span className="correct-char-stats">{statsCharCount[4]}</span>/
                <span className="incorrect-char-stats">{statsCharCount[5]}</span>
              </h3>
            </Tooltip>
          )}
          {status === "finished" && (
            <h3>
              Raw KPM: {Math.round((rawKeyStrokes / countDownConstant) * 60.0)}
            </h3>
          )}
        </Box>
      </Slide>
    </>
  );
};

export default Stats;
